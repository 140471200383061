import React from "react"
import { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./404.scss"
import { inject } from "@vercel/analytics"

const NotFoundPage = () => {
  useEffect(() => {
    inject()
  }, [])

  useEffect(() => {
    window.addEventListener(
      "contextmenu",
      function(e) {
        // do something here...
        e.preventDefault()
      },
      false
    )
  }, [])

  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="notfound">
        <h1>PAGE NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
